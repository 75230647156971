import React, { useState, useEffect } from "react";

// Material
import { Avatar, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons'

// Graphql
import { request, gql } from 'graphql-request'

const query = gql`
  query GetLitters {
    litters {
      date
      sire {
        name
      }
      dam {
        name
      }
      animals {
        id
        name
        photo {
          url
        }
      }
    }
  }
`

const useStyles = makeStyles(({ palette, ...theme }) => ({
  root: {
    minWidth: 275,
  },
  pos: {
    marginBottom: 12,
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  avatars: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const Kittens = ({type}) => {
  const classes = useStyles();
  const [litters, setLitters] = useState([]);

  useEffect(() => {
    request(process.env.REACT_APP_BASE_URL, query).then(data => setLitters(data.litters))
  }, []);

  return (
    <Grid container spacing={3}>
      {litters.map((litter, ind) => (
        <Grid key={ind} item lg={6} md={12} sm={12} xs={12}>
          <Card className={classes.root}>
            <CardContent>
              <Typography className={classes.pos}>
                {litter.date}
              </Typography>
              <table>
                <thead>
                  <tr>
                   <th><FontAwesomeIcon icon={faMars} /> {litter.sire.name}</th>
                   <th><FontAwesomeIcon icon={faVenus} /> {litter.dam.name}</th>
                 </tr>
               </thead>
             </table>
              <Typography variant="body2" component="div">
                <div className={classes.avatars}>
                  {litter.animals.map((cat, index) => (
                    <a href={'/cats/' + cat.id}>
                      <Avatar key={index} alt={cat.name} src={cat.photo.url} className={classes.large} />
                    </a>
                  ))}
                </div>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Kittens;
