import React, { useState, useEffect } from "react";
import { withCommonStyle, withHeader } from '../Layout/functions'

// Material
import { Avatar, Box, Typography, Modal, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material extra
import OrgChart from '@mui-treasury/components/chart/org';

// Graphql
import { request, gql } from 'graphql-request'

// Lodash
import find from 'lodash/find';
import isArray from 'lodash/isArray';

import Pedigree from 'react-pedigree-table';

import { Row, Item } from '@mui-treasury/components/flex';
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info';
import { useTutorInfoStyles } from '@mui-treasury/styles/info/tutor';
import { useSizedIconButtonStyles } from '@mui-treasury/styles/iconButton/sized';
import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic';



const tree = {
  name: "Cat",
  mother: {
    name: "Mother cat",
    father: {
      name: "Father cat 1",
    },
    mother: {
      name: "Mother cat 1",
    }
  },
  father: {
    name: "Father cat",
    father: {
      name: "Father cat 1",
    },
    mother: {
      name: "Mother cat 1",
    }
  }
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    marginRight: 12,
  },
  avatar: {
    borderRadius: 8,
    backgroundColor: '#495869'
  },
  overline: {
    fontSize: 10,
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: '#8D9CAD',
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    color: '#495869',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    overflow: 'scroll',
    maxWidth: '80%',
    height: '80%',
  }
}));

const query = gql`
query GetDocumment($animalId: ID!) {
  familyTree(animalId: $animalId) {
    nodes {
      id
      name
      level
      side
      sireId
      damId
      cfaRegistrationNumber
      color
      image {
        url
      }
    }
  }
}
`

const Documents = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    request(process.env.REACT_APP_BASE_URL, query, { animalId: props.catId }).then((response) => setData(response.familyTree.nodes))
  }, []);

  const tags = data

  const rootTag = tags.find(tag => tag.level === 0);
  const addParents = (tag) => {
    const sireTag = tags.find(temp_tag => temp_tag.id === tag.sireId);
    const damTag = tags.find(temp_tag => temp_tag.id === tag.damId);

    if (sireTag) { addParents(sireTag) }
    if (damTag) { addParents(damTag) }

    tag.father = sireTag
    tag.mother = damTag
  }

  if (rootTag) { addParents(rootTag) }

  const styles = useStyles();
  const avatarStyles = useDynamicAvatarStyles({ radius: 12, size: 48 });

  if (!rootTag) return null

  const generator = (item) => {
    return (
      <Row p={1.5} gap={2} bgcolor={'#f5f5f5'} borderRadius={16}>
         <Item>
           <Avatar
             classes={avatarStyles}
             src={
               item.image?.url
             }
           />
         </Item>
         <Info position={'middle'} useStyles={useTutorInfoStyles}>
           <InfoTitle><a href={'/cats/' + item.id}>{item.name}</a></InfoTitle>
           <InfoSubtitle><b>Color:</b> {item.color || '-' }</InfoSubtitle>
           <InfoSubtitle><b>CFA Nr:</b> {item.cfaRegistrationNumber || '-' }</InfoSubtitle>
         </Info>
       </Row>
    );
  }
  return (
    <Modal
      open={props.open}
      onClose={() => { props.toggleOpen(false) }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={styles.modal}
    >
    <Paper className={styles.paper}>
      <Pedigree
          pedigreeData={rootTag}
          cellGenerator={generator}
          leftBranchFieldName={"father"}
          rightBranchFieldName={"mother"}
          className={styles.table}
          depth={4} />
      </Paper>
    </Modal>
  );
};

export default Documents;
