import React from "react";

import Header from "./Landing/Header";
import News from "./Landing/News";
import Testimonial from "./Landing/Testimonial";

const Landing = () => {
  return (
    <>
      <Header />
      <News />
      <Testimonial />
    </>
  );
};

export default Landing;
