import React from "react";
import { withCommonStyle, withHeader } from '../Layout/functions'

// Material
import { Box, Tab, Tabs } from "@material-ui/core";

// business
import CatsList from './CatsList'
import Kittens from './Kittens'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const header = 'Cats'
const subheader = `
  Down below you will see the cats which are currently used for breeding purposes in our cattery.
  In the kittens tab you will be able to see all of the litters produced by our cattery throughout the years.
`

const Cats = () => {
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => { setValue(newValue) }

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Males" />
        <Tab label="Females" />
        <Tab label="Kittens" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <CatsList type='male' />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CatsList type='female' />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Kittens />
      </TabPanel>
    </>
  );
};

export default withCommonStyle(withHeader(Cats, header, subheader));
