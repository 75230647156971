import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Scrollbar from "react-perfect-scrollbar";
import { Theme } from "./theme";
import GlobalCss from "./styles/jss/GlobalCss";

import Layout from './Layout.jsx'

import Landing from "./home/Landing";
import Cats from "./home/Cats";
import Cat from "./home/Cat";
import Show from "./home/Show";
import Gallery from "./home/Gallery";
import Shows from './home/Shows';
import Contacts from './home/Contacts';
import Document from './home/Document';
import About from './home/About';

function App() {
  return (
    <MuiThemeProvider theme={Theme}>
      <GlobalCss>
        <Scrollbar
          className="h-full-screen scrollable-content"
          options={{ suppressScrollX: true }}
        >
          <Router basename="/">
            <Layout>
              <Switch>
                <Route path="/cats" component={Cats} exact />
                <Route path="/cats/:id" component={Cat} />
                <Route path="/documents/:id" component={Document} />
                <Route path="/gallery" component={Gallery} />
                <Route path="/shows" component={Shows} exact />
                <Route path="/shows/:id" component={Show} />
                <Route path="/contacts" component={Contacts} />
                <Route path="/about" component={About} />
                <Route path="/" component={Landing} />
                <Redirect path="/" exact to="/" />
              </Switch>
            </Layout>
          </Router>
        </Scrollbar>
      </GlobalCss>
    </MuiThemeProvider>
  );
}

export default App;
