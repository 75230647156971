import React, { useState, useEffect } from "react";
import clsx from "clsx";

// Material
import { Grid, Card, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Graphql
import { request, gql } from 'graphql-request'

const query = gql`
  {
    posts(limit: 6) {
      id, name, content, date, image { url }
    }
  }
`

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    borderRadius: 20,
    border: `1px solid transparent`,
    "&:hover": {
      border: `1px solid ${palette.primary.main}`,
    },
    "&:hover $imageOverlay": {
      opacity: 1,
    },
  },
  imageOverlay: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1,
    opacity: 0,
    background: "rgba(0,0,0,0.67)",
    transition: "all 250ms ease",
  },
  dateHolder: {
    borderRadius: 4,
    padding: "0.3rem 0.6rem",
    background: "rgba(var(--primary),0.4)",
    textAlign: "center",
    marginRight: "1rem",
  },
}));

const News = () => {
  const classes = useStyles();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    request(process.env.REACT_APP_BASE_URL, query).then((data) => setPosts(data.posts))
  }, []);

  return (
    <section className="section section-bg-light-primary" id="blog1">
      <div className="container">
        <div className="max-w-400 mb-16 text-center mx-auto">
          <h1 className="mt-0 font-normal text-44">Latest News</h1>
          <p>
            See the latest shows, events, upcomming litters below
          </p>
        </div>
        <Grid container spacing={2}>
          {posts.map((item, ind) => (
            <Grid key={ind} item md={4} sm={6} xs={12}>
              <Card elevation={3} className={clsx(classes.card, "card")}>
                <div className="relative">
                  <img
                    className="w-full block"
                    src={item.image?.url}
                    alt="sq-1"
                  />
                  <div className={classes.imageOverlay}>
                    <Button
                      className="text-white"
                      variant="text"
                      color="primary"
                      href={'/shows/' + item.id }
                    >
                      Click to read more
                    </Button>
                  </div>
                </div>
                <div className="px-4 pt-4">
                  <div className="flex items-center">
                    <h5 className="m-0">{item.name}</h5>
                  </div>
                  <p className="mt-6 mb-8" dangerouslySetInnerHTML={{__html: item.content || '-' }}>
                  </p>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};

export default News;
