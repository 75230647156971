import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Link
} from "@material-ui/core";
import { darken } from "@material-ui/core/styles";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  section: {
    background: "#011C3A",
    color: palette.primary.contrastText,
  },
  link: {
    borderRadius: 4,
    "&:hover": {
      background: darken("#011C3A", 0.2),
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <section className={`section ${classes.section}`} id="contact4">
      <div className="container">
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="subtitle1" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://material-ui.com/">
                <b>Svajone</b>
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
              <b>Follow us at</b>:
            </Typography>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default Footer;
