import React from 'react';

import TopBar from "./Layout/TopBar";
import Footer from "./Layout/Footer";

function Layout(props) {
  return (
    <>
      <TopBar />
        { props.children }
      <Footer />
    </>
  )
}

export default Layout
