import React, { useEffect, useState } from "react";
import { withCommonStyle, withHeader } from '../Layout/functions'
import clsx from "clsx";

// Material
import { Card, TextField, Button, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from "@material-ui/core/styles";

import { request, gql } from 'graphql-request'

const useStyles = makeStyles(({ palette, ...theme }) => ({
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const inputProps = {
  style: {
    borderRadius: 8,
  },
};

const header = `Contact <span className="text-primary">us</span>`
const subheader = `
Interested in buying our cats or kittens? Do you have any business proposals? Need help on starting your own cattery?
It does not matter what the question is - feel free to reach out to us and we will try to help you.
`

const query = gql`
  mutation CreateMessage($name: String, $email: String, $content: String) {
    createMessage(name: $name, email: $email, content: $content) {
      id
    }
  }
`

const Contacts = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const [statusOpen, setStatusOpen] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const submit = () => {
    setLoading(true)

    request(process.env.REACT_APP_BASE_URL, query, { ...message }).then((data) => {
      setLoading(false)
      setSubmitSuccess(data.createMessage.id)
      setStatusOpen(true)

      if (data.createMessage.id) setMessage({})
    })
  }


  return (
    <Card
      className={clsx("py-10 px-6 max-w-550 mx-auto", classes.card)}
      elevation={3}
    >
      <Snackbar open={statusOpen} autoHideDuration={6000} onClose={() => { setStatusOpen(false) }}>
        <Alert onClose={() => { setStatusOpen(false) }} severity={ submitSuccess ? "success" : 'error' }>
          { submitSuccess ? 'Message sent successfully' : 'Message failed to be sent. Please try again' }
        </Alert>
      </Snackbar>
      <form>
        <TextField
          className="mb-6 mr-4"
          label="Your Name"
          placeholder="First Name"
          value={message.name || ''}
          onChange={(e) => { setMessage({ ...message, name: e.target.value }) }}
          size="small"
          variant="outlined"
          fullWidth
          InputProps={inputProps}
        />
        <TextField
          className="mb-6 mr-4"
          label="Email"
          placeholder="Email"
          value={message.email || ''}
          onChange={(e) => { setMessage({ ...message, email: e.target.value }) }}
          size="small"
          variant="outlined"
          fullWidth
          InputProps={inputProps}
        />
        <TextField
          className="mb-6"
          label="Description"
          placeholder="Message"
          size="small"
          value={message.content || ''}
          onChange={(e) => { setMessage({ ...message, content: e.target.value }) }}
          multiline
          rows={8}
          variant="outlined"
          fullWidth
          InputProps={inputProps}
        />
        <Button
          className="w-full border-radius-8"
          variant="contained"
          color="primary"
          onClick={submit}
          disabled={loading}
        >
          SUBMIT
        </Button>
      </form>
    </Card>
  );
};

export default withCommonStyle(withHeader(Contacts, header, subheader));
