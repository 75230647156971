import React, { useState, useEffect } from "react";
import { withCommonStyle } from '../Layout/functions'
import clsx from 'clsx'

// Material
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Graphql
import { request, gql } from 'graphql-request'

// Business
import ProfilePhoto from './Cat/ProfilePhoto'
import Comment from './Cat/Comment'
import Titles from './Cat/Titles'
import Bloodlines from './Cat/Bloodlines'
import Actions from './Cat/Actions'
import Bio from './Cat/Bio'
import Photos from './Cat/Photos'
import Document from './Document';

const useStyles = makeStyles(({ palette, ...theme }) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop: 64,
    color: 'red'
  },
  card: {
    borderTop: "2px solid black",
    "& .icon": {
      fontSize: 64,
    },

    "&:hover": {
      borderTop: "2px solid rgba(var(--primary), 1)",
      "& .icon": {
        color: "rgba(var(--primary),1)",
      },
    },

    "& .card-header": {
      background: "rgba(0, 0, 0, 0.024)",
    },
    "& .card-header-highlighted": {
      background: "rgba(var(--primary),1)",
      "& span": {
        color: "#fff",
      },
    },
  },
  media: {
    height: 300,
    width: '100%',
    objectFit: 'cover'
  },
  cardHolder: {
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    "&:hover $cardOverlay": {
      opacity: 1,
    },
  },
  cardOverlay: {
    padding: "0px 1rem",
    transition: "all 250ms ease-in-out",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "absolute",
    borderRadius: 8,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    color: palette.primary.contrastText,
    background: "rgba(0,0,0,0.67)",
    zIndex: 5,
  },
  cardTitle: {
    borderBottom: "1px solid rgba(255,255,255,0.87)",
  },
  marginTop: {
    marginTop: 24,
  },
  sizedPhoto: {
    minHeight: 440,
  },
  table: {
    width: '100%',
    "& td.bioHeader": {
      textAligh: 'left',
      align: 'left',
      float: 'left',
      fontWeight: 'bold'
    },
  }
}));

const query = gql`
  query Cat($id: ID!) {
    animal(id: $id) {
      id,
      basedata {
        name
        cfaRegistrationNumber
        birthdate
        gender {
          value,
          label
        },
        color {
          value,
          label
        },
        sire {
          value,
          label
        },
        dam {
          value,
          label
        },
        sireObject {
          basedata {
            name
            cfaRegistrationNumber
            birthdate
          }
        },
        damObject {
          basedata {
            name
            cfaRegistrationNumber
            birthdate
          }
        }
      },
      photos {
        id,
        url,
        isDefault,
        isCub
      },
      metadata {
        titles {
          value,
          label
        },
        bloodlines {
          value,
          label
        }
      },
      comment,
    }
  }
`

const Cat = (props) => {
  const classes = useStyles();
  const [cat, setCat] = useState({});
  const [documentOpen, toggleDocumentOpen] = useState(false);

  useEffect(() => {
    request(process.env.REACT_APP_BASE_URL, query, { id: props.match.params.id }).then(data => setCat(data.animal))
  }, [props.match.params.id]);

  const loading = !cat.basedata

  return (
    <Grid container spacing={3} className={clsx(classes.marginTop, 'mb-8')}>
      <Grid item lg={4} xs={12}>
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <ProfilePhoto cat={cat} loading={loading} />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Comment cat={cat} loading={loading} />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Titles cat={cat} loading={loading} />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Bloodlines cat={cat} loading={loading} />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Actions cat={cat} loading={loading} showDocuments={() => { toggleDocumentOpen(true) }} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={8} xs={12}>
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <Bio cat={cat} loading={loading} />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Photos cat={cat} loading={loading} />
          </Grid>
        </Grid>
      </Grid>

      {cat.id && <Document open={documentOpen} toggleOpen={toggleDocumentOpen} catId={cat.id}/>}
    </Grid>
  );
};

export default withCommonStyle(Cat);
