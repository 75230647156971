import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  introWrapper: {
    position: "relative",
    background:
      "url(./assets/images/scene-3.jpg) center center/cover no-repeat",
    padding: "2.5rem 0rem",
  },
  lightWhiteBG: {
    position: "absolute",
    display: "block",
    top: 0,
    bottom: 0,
    left: 0,
    right: "calc(50% - 395px)", // 1170*(1-5/7)
    minWidth: 800,
    background: "rgba(255,255,255,0.25)",
    clipPath: "polygon(0 0, 100% 0%, 75% 100%, 0% 100%)",

    "&:before": {
      content: '" "',
      position: "absolute",
      display: "block",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      marginRight: 100,
      minWidth: 800,
      background: "rgba(255,255,255,0.75)",
      clipPath: "polygon(0 0, 100% 0%, 75% 100%, 0% 100%)",
      zIndex: -1,
    },

    [theme.breakpoints.down("xs")]: {
      right: 0,
      clipPath: "none",
      background: "rgba(255,255,255,0.7)",
    },
  },
  intro: {
    position: "relative",
    padding: "5rem 0rem",
    maxWidth: 500,
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0rem",
    },
    zIndex: 5,
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.introWrapper}>
      <div className="container">
        <div className={classes.intro}>
          <h4 className="font-normal m-0 mb-6">
            CFA registered Persian end Exotic cattery
          </h4>
          <h1 className="font-medium text-48 text-primary mt-0">
            Svajone
          </h1>
          <p className="mb-16">
            Our cattery Svajonė has been a member of the CFA since 2005. We’ve been breeding Exotic cats for more than 10 years now. We adore doing what we do and we’re happy to have made friends who have the same goals. To breed this kind of cats is a true satisfaction.
          </p>
          <div className="flex flex-wrap">
            <Button
              variant="contained"
              color="primary"
              className="mr-4 border-radius-8"
              href='/about'
            >
              Read more
            </Button>
          </div>
        </div>
        <div className={classes.lightWhiteBG}></div>
      </div>
    </div>
  );
};

export default Header;
