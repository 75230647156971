import React, { useState, useEffect } from "react";
import { withCommonStyle } from '../Layout/functions'
import clsx from 'clsx'

// Material
import { Card, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

// Graphql
import { request, gql } from 'graphql-request'

// Extra
import ImageSlides from 'react-imageslides';

// Business
import ProfilePhoto from './Cat/ProfilePhoto'
import Comment from './Cat/Comment'
import Titles from './Cat/Titles'
import Bloodlines from './Cat/Bloodlines'
import Actions from './Cat/Actions'
import Bio from './Cat/Bio'
import Photos from './Cat/Photos'
import Document from './Document';

const useStyles = makeStyles(({ palette, ...theme }) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop: 64,
    color: 'red'
  },
  card: {
    borderTop: "2px solid black",
    "& .icon": {
      fontSize: 64,
    },

    "&:hover": {
      borderTop: "2px solid rgba(var(--primary), 1)",
      "& .icon": {
        color: "rgba(var(--primary),1)",
      },
    },

    "& .card-header": {
      background: "rgba(0, 0, 0, 0.024)",
    },
    "& .card-header-highlighted": {
      background: "rgba(var(--primary),1)",
      "& span": {
        color: "#fff",
      },
    },
  },
  media: {
    height: 300,
    width: '100%',
    objectFit: 'cover'
  },
  cardHolder: {
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    "&:hover $cardOverlay": {
      opacity: 1,
    },
  },
  cardOverlay: {
    padding: "0px 1rem",
    transition: "all 250ms ease-in-out",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "absolute",
    borderRadius: 8,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    color: palette.primary.contrastText,
    background: "rgba(0,0,0,0.67)",
    zIndex: 5,
  },
  cardTitle: {
    borderBottom: "1px solid rgba(255,255,255,0.87)",
  },
  marginTop: {
    marginTop: 24,
  },
  sizedPhoto: {
    minHeight: 440,
  },
  table: {
    width: '100%',
    "& td.bioHeader": {
      textAligh: 'left',
      align: 'left',
      float: 'left',
      fontWeight: 'bold'
    },
  }
}));

const query = gql`
  query Post($id: ID!) {
    post(id: $id) {
      id, name, content:fullContent, date, image { url }, photos { url }
    }
  }
`

const Show = (props) => {
  const classes = useStyles();
  const [post, setPost] = useState({});

  useEffect(() => {
    request(process.env.REACT_APP_BASE_URL, query, { id: props.match.params.id }).then(data => setPost(data.post))
  }, [props.match.params.id]);

  const loading = !post.id

  const [photosOpen, togglePhotosOpen] = useState(false);
  const [clickedPhotoIndex, changeClickedPhotoIndex] = useState(0);

  const handleKeyDown = (event) => {
    if (event.keyCode == 27 || event.keyCode == 13) {
      togglePhotosOpen(false)
    };
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const photos = loading ? [] : post.photos
  const photoLinks = photos.map((it) => it.url)

  return (
    <>
      <Card className={clsx("text-center card", classes.card)}>
        <div
          className={clsx({
            "card-header flex-column items-center p-3": true,
            "card-header-highlighted": false,
          })}
        >
          <span className="text-16"><b>{post.name} ({post.date})</b></span>
        </div>
        <Divider className="mb-2" />
        <CardContent className="pricing1__card-content">
          <Grid container spacing={3}>
            <Grid item lg={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={12} xs={12}>
                  <Card className={clsx("text-center")}>
                  <CardContent className="pricing1__card-content" dangerouslySetInnerHTML={{__html: post.content || '-' }} />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={clsx("text-center card mt-4", classes.card)}>
        <div
          className={clsx({
            "card-header flex-column items-center p-3": true,
            "card-header-highlighted": false,
          })}
        >
          <span className="text-16">{'Photos'}</span>
        </div>
        <Divider className="mb-2" />
        <CardContent className="pricing1__card-content">
          <Grid container spacing={3}>
            {photos.map((photo, index) => {
              return (
                <Grid key={index} item lg={3} md={6} sm={12}>
                  <img
                    className="w-full block"
                    src={photo.url}
                    alt="random"
                    onClick={() => { changeClickedPhotoIndex(index + 1); togglePhotosOpen(true) }}
                  />
                </Grid>
              )
            })}
          </Grid>
        </CardContent>

        <ImageSlides
          images={photoLinks}
          index={clickedPhotoIndex}
          tapClose={true}
          isOpen={photosOpen}
          onClose={() => { togglePhotosOpen(false) }}
          showPageButton
        />
      </Card>
    </>
  );
};

export default withCommonStyle(Show);
