import React, { useState, useEffect } from "react";
import { withCommonStyle, withHeader } from '../Layout/functions'
import clsx from 'clsx'

// Material
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons'

// Graphql
import { request, gql } from 'graphql-request'

const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    "&:hover $cardOverlay": {
      opacity: 1,
    },
  },
  cardOverlay: {
    padding: "0px 1rem",
    transition: "all 250ms ease-in-out",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "absolute",
    borderRadius: 8,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    color: palette.primary.contrastText,
    background: "rgba(0,0,0,0.67)",
    zIndex: 5,
  },
  cardTitle: {
    borderBottom: "1px solid rgba(255,255,255,0.87)",
  },
}));

const query = gql`
  query GetMales($type: String!) {
    animals(type: $type) {
      id
      name
      birthdate
      photo {
        url
      }
      sire {
        id
        name
      }
      dam {
        id
        name
      }
    }
  }
`

const header = 'Gallery'
const subheader = `
  Welcome to the gallery. This is the place where we list all of our cats which were part of our cattery at some point, but no longer are with us.
  Feel free to open the cat profiles one by one where you will find more detailed information on where the cat currently is.
`

const Gallery = () => {
  const classes = useStyles();

  const [cats, setCats] = useState([]);

  useEffect(() => {
    request(process.env.REACT_APP_BASE_URL, query, { type: 'for_gallery' }).then((data) => setCats(data.animals))
  }, []);

  return (
    <Grid container spacing={3}>
      {cats.map((item, ind) => (
        <Grid key={ind} item lg={4} md={4} sm={4} xs={12}>
          <a href={'/cats/' + item.id}>
            <div className={classes.cardHolder}>
              <img
                className="w-full block"
                src={item.photo?.url}
                alt="random"
              />
              <div className={classes.cardOverlay}>
                <div>
                  <h5
                    className={clsx(
                      "m-0 mb-2 pb-2 font-medium inline-block",
                      classes.cardTitle
                    )}
                  >
                    {item.name}
                  </h5>
                </div>
                <div className="flex items-center mb-2">
                   <FontAwesomeIcon icon={faMars} />
                   <span className="ml-2 mr-4">{item.sire?.name}</span>
                   <FontAwesomeIcon icon={faVenus} />
                   <span className="ml-2 mr-4">{item.dam?.name}</span>
                </div>
              </div>
            </div>
          </a>
        </Grid>
      ))}
    </Grid>
  );
};

export default withCommonStyle(withHeader(Gallery, header, subheader));
