import React, { useState, useEffect } from "react";
import { withCommonStyle, withHeader } from '../Layout/functions'

// Material
import { Card, Grid } from "@material-ui/core";

// Graphql
import { request, gql } from 'graphql-request'

const query = gql`
  {
    posts {
      id, name, content, date, image { url }
    }
  }
`

const header = 'Shows'
const subheader = `
  Shows are types of events where titles to cats are issued if they meet the required criteria.
  We do participate in a variety of different shows across the world and we want to share this experience with you.
  If you have any questions, feel free to reach out to us - we are willing to help!
`

const Shows = () => {
  const [shows, setShows] = useState([]);

  useEffect(() => {
    request(process.env.REACT_APP_BASE_URL, query).then((data) => setShows(data.posts))
  }, []);

  return (
    <Grid container spacing={3}>
      {shows.map((show, index) => (
        <Grid item md={4} sm={4} key={index}>
          <a href={'/shows/' + show.id}>
            <Card className="relative h-full card" key={index}>
              <img
                className="w-full"
                src={show.image?.url}
                alt="portfolio"
              />
              <div className="flex-column justify-between">
                <div className="px-4 pt-4">
                  <h5 className="m-0 text-16 font-bold">{show.name}</h5>
                  <p className="mb-4" dangerouslySetInnerHTML={{__html: show.content || '-' }}/>
                </div>
              </div>
            </Card>
          </a>
        </Grid>
      ))}
    </Grid>
  );
};

export default withCommonStyle(withHeader(Shows, header, subheader));;
