import React, { useState } from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

const TopBar = (props) => {
  const [isClosed, setIsClosed] = useState(true);
  let toggleIcon = isClosed ? "menu" : "close";

  return (
    <section
      className={clsx({
        header: true,
        "header-fixed": true,
        closed: isClosed,
      })}
    >
      <div className="container header-container">
        <div className="brand" style={{color: '#3f51b5', fontSize: 22}}>
          <b>Svajone</b>
        </div>
        <ul className="navigation">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/cats">Cats</NavLink>
          </li>
          <li>
            <NavLink to="/gallery">Gallery</NavLink>
          </li>
          <li>
            <NavLink to="/shows">Shows</NavLink>
          </li>
          <li>
            <NavLink to="/contacts">Contacts</NavLink>
          </li>
        </ul>
        <div className="m-auto" />
        <IconButton
          className="header__toggle"
          onClick={() => {
            setIsClosed(!isClosed);
          }}
        >
          <Icon>{toggleIcon}</Icon>
        </IconButton>
      </div>
    </section>
  );
};

export default TopBar;
