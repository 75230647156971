import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  margin: {
    marginTop: 80,
    marginBottom: 80
  },
  background: {
    position: "relative",
    overflow: "visible",
    "&:before": {
      content: '" "',
      position: "absolute",
      height: 350,
      width: 350,
      left: -450,
      top: "75%",
      background: "rgba(var(--primary), 0.1)",
      transform: "translateY(-50%); rotate(40deg)",
      transformOrigin: "top right",
      borderRadius: "15%",
      zIndex: -1,
    },
    "&:after": {
      content: '" "',
      position: "absolute",
      height: 350,
      width: 350,
      right: -450,
      top: "25%",
      background: "rgba(var(--primary), 0.1)",
      transform: "translateY(-50%); rotate(-60deg)",
      transformOrigin: "top left",
      borderRadius: "15%",
      zIndex: -1,
    },
  },
}));

export const withCommonStyle = Component => ({ ...props }) => {
  const classes = useStyles()

  return (
    <div className={clsx('container', 'text-center', classes.margin, classes.background)}>
      <Component {...props} />
    </div>
  )
};

export const withHeader = (Component, header, subheader) => ({ ...props }) => {
  return (
    <>
      <h1 className="font-normal text-48 mt-0 mb-4 text-primary" dangerouslySetInnerHTML={{__html: header || '' }} />
      <p className="mb-16 mx-auto" dangerouslySetInnerHTML={{__html: subheader || props.subjeader || '' }} />
      <Component {...props} />
    </>
  )
};
