import React, { useEffect, useState } from "react";
import { withCommonStyle, withHeader } from '../Layout/functions'
import clsx from "clsx";

// Material
import { Card, TextField, Button, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from "@material-ui/core/styles";

import { request, gql } from 'graphql-request'

const useStyles = makeStyles(({ palette, ...theme }) => ({
}));

const header = `About Us`
const subheader = null


const About = () => {
  const classes = useStyles();

  return (
    <>
      <img src='./assets/images/us.jpg'/>
      <p>We are Dalia and Eligijus. We live in the capital of Lithuania – Vilnius, Europe.</p>
      <p>Our cattery <b>Svajonė</b> has been a member of the <i>CFA</i> since 2005. We’ve been breeding <i>Exotic</i> cats for more than 10 years now. We adore doing what we do and we’re happy to have made friends who have the same goals. To breed this kind of cats is a true satisfaction.</p>
      <p>Our first cats came from the cattery Kis’Herbas. Their names were "Kis'Herbas Vinnetu" (later - RW SGC Kis'Herbas Vinnetu of Caramia) and Kis'Herbas Issima. I am really grateful to my good friend Gražina who helped me provide the blood lines of my cats with good blood and gave me really useful advice at the very beginning of my cattery’s journey. Special thanks go to our veterinarian Irena with whom we’ve been working for many years now. She is the one that is ready to help us no matter what and always finds some time to support us.</p>
      <p>Our purpose is to breed healthy and beautiful cats which would have an open face, expressive eyes, a big head, a short and strong body, short massive legs, a little fluffy tail, heavy and thick bones and which would generally look like a warm-hearted teddy bear with an eternal childish expression on their faces. We pay a lot of attention to the quality of the fur. It must be dense, soft and shiny. I believe that a good animal will always look equally beautiful in any colour of its fur,  however, we tend to work more with Silver Tabby/Whites and Bi-Colours. I consider a cat that owns big expressive eyes to be the main representative of our cattery, so I hope we almost always succeed in obtaining it.</p>
      <p>We keep the best kittens to proceed with the breeding out of every litter we have and try not to sell them until they provide us with babies. From time to time, we look for some new cats in order to benefit current blood lines with something better. Of course, not always is the new cat Silver Tabby. It’s important to evaluate all of its characteristics in general, and the colour is not the priority.</p>
      <p>During recent years, the standard of Exotic cats has increased considerably, that’s why neither did we have time to relax. Even if the litter looks really successful, we keep willing to get something better the next time. We cannot promise that our cats are completely flawless, but we will be working towards our goals and trying to breed our cats as good as possible.</p>
      <p>We live in a spacious house. What’s meant by that is that our cats have enough freedom and space for playing. It is clear that they feel how much we care about them because they provide us with the same amount of love and make friends with everyone easily.</p>
      <p>Our cattery is registered in the Public Veterinary Service and is constantly visited by veterinary inspectors who always find our cats well cared for and clean. One of the most important things for us is to see our cats healthy and happy. All of them are only bred from PKD DNA negative cats (laboratory tests were carried out at USA DAVIS and Australian GRIBBLES Gen laboratories). Without doubt, all cats get regular Rabies, Feline Infectious Enteritis and Respiratory Viral Diseases vaccines and leave our cattery only fully vaccinated and having a microchip and the European Veterinary Passport. Our cattery is Fungus free.</p>
      <p>We wish that not only do our cats bring joy and happiness to us, but also to all the families that become their new home.</p>
      <p>We are grateful to everyone who has found some time to visit our website. We are looking forward to hearing from you.</p>
    </>
  );
};

export default withCommonStyle(withHeader(About, header, subheader));
