import React, { useState, useEffect } from "react";
import { Avatar, Icon, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Carousel from "../common/Carousel";
import clsx from "clsx";
// Graphql
import { request, gql } from 'graphql-request'

const useStyles = makeStyles(({ palette, ...theme }) => ({
  section: {
    background: `linear-gradient(to left, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%),
    url('./assets/images/scene-2.jpg')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  card: {
    maxWidth: 700,
  },
  review: {
    transform: "skewY(0.75deg)",
    transformOrigin: "bottom left",
  },
}));

const query = gql`
  {
    reviews {
      id, author, position, comment, image { url }
    }
  }
`

const Testimonial8 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [reviews, setReviews] = useState([])

  useEffect(() => {
    request(process.env.REACT_APP_BASE_URL, query).then((data) => setReviews(data.reviews))
  }, [])

  const testimonialList = [
    {
      companyLogoUrl: "./assets/images/mock-logo-1.png",
      comment: `"Svajone's Cattery is an excellent breeder!
        My exotic shorthair Opal Glass from Svajone's is smart, loving, mild-mannered and
        at 11 years old continues to be healthy and active. Thank you Svajone's for Opal Glass!"`,
      user: {
        imageUrl: "./assets/images/face-1.png",
        name: "John Doe",
        designation: "Product Manager",
      },
    },
    {
      companyLogoUrl: "./assets/images/mock-logo-2.png",
      comment: `"Dalia is an excellent breeder with beautiful cats.
        A few years ago I purchased from her a proven breeder, Ch. Svajone's Queen.
        Everything went as planned and when I received her she was in excellent condition and obviously
        loved and cared for. She went on to produce a healthy litter of 4 kitten for me. I would not hesitate
        to recommend Dalia to anyone."`,
      user: {
        imageUrl: "./assets/images/face-2.png",
        name: "Adam Smith",
        designation: "CEO",
      },
    },
    {
      companyLogoUrl: "./assets/images/mock-logo-2.png",
      comment: `"I have had the pleasure of friendship with Dalia now for 8 years
  I was very happy when she allowed me to have one of her wonderful kittens 8 years ago
  That was my beautiful Daisy
  When I got her I was so pleased with the quality of the kitten and the personality she was so affectionate with always shows a good breeder giving the kittens plenty of attention,  I t not long after that I got my second kitten from Dalia and again another stunning kitten
  I have always found that with Dalia she is so helpful in every way and makes it so easy with the transportation of the kittens
  Only this year I got my first boy from Dalia and again what a fantastic boy and a wonderful stud and producing top quality kittens
  I would recommend Dalia to any other breeder, they will not be disappointed."`,
      user: {
        imageUrl: "./assets/images/face-2.png",
        name: "Adam Smith",
        designation: "CEO",
      },
    }
  ];

  return (
    <div className={clsx("section", classes.section)} id="testimonial8">
      <div className="container text-center">
        <h1 className="font-normal text-44 mt-0 text-white mx-auto mb-16">
          What our partners have to say
        </h1>
        <Carousel
          bulletColor={theme.palette.primary.contrastText}
          slidesPerView={1}
          spacing={0}
          navigation={false}
          paginationClass="mt-16"
          carouselId='testimonial'
        >
          {reviews.map((review, index) => (
            <div className={clsx("mx-auto", classes.card)} key={index}>
              <div
                className={clsx({
                  "flex justify-center": true,
                  "flex-wrap": isMobile,
                })}
              >
                <Avatar
                  className="w-108 h-108"
                  src={review.image.url}
                  alt="user"
                />
                <p
                  className={clsx({
                    "text-white": true,
                    "text-left my-0 ml-8": !isMobile,
                    [classes.review]: true,
                  })}
                  dangerouslySetInnerHTML={{__html: review.comment || '-' }}
                />
              </div>
              <div className="flex flex-wrap mt-4 justify-center mb-2">
                {[1, 2, 3, 4, 5].map((i) => (
                  <Icon
                    key={i}
                    className="mx-1"
                    fontSize="small"
                    color="secondary"
                  >
                    start
                  </Icon>
                ))}
              </div>
              <h5 className="inline-block m-0 font-medium text-white">
                {review.author}, {review.position}
              </h5>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonial8;
